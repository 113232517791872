import { Suspense, lazy } from "react";
import { useRoutes } from "react-router-dom";

import LoadingScreen from "./components/LoadingScreen";
// import Carousel from "./views/carousel/index";
// import Weather from "./views/weather/index";
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

const Carousel = Loadable(
  lazy(() => {
    try {
      const script = document.createElement("script");
      script.src = "./adapt.js";
      document.body.appendChild(script);
    } catch (e) {
      console.log(e);
    }
    return import("./views/carousel/index");
  })
);

const Weather = Loadable(lazy(() => import("./views/weather/index")));
const Index = Loadable(lazy(() => import("./views/index")));

export default function Router() {
  return useRoutes([
    {
      path: "/carousel",
      element: <Carousel />,
    },
    {
      path: "/weather",
      element: <Weather />,
    },
    {
      path: "/*",
      element: <Index />,
    },
  ]);
}

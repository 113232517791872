import { createContext, useContext, useState } from "react";

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
const firebaseConfig = {
  apiKey: "AIzaSyA8XWsQ5rn0dhadVPByx3KaW_dEPXqEUrk",
  authDomain: "chrome-channeling.firebaseapp.com",
  projectId: "chrome-channeling",
  storageBucket: "chrome-channeling.appspot.com",
  messagingSenderId: "955351386934",
  appId: "1:955351386934:web:1951d94799c4d7615e8197",
  measurementId: "G-S7HL2KN8HW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const analytics = getAnalytics(app);

onMessage(messaging, (payload) => {
  console.log("onMessage payload", payload);
  if (
    payload.notification &&
    payload.notification.title &&
    payload.notification.body &&
    payload.data.url
  ) {
    // window.self.registration.showNotification(payload.notification.title, {body:payload.notification.body,data:payload.data.url});
    try {
      const notification = new Notification(payload.notification.title, {
        body: payload.notification.body,
        data: payload.data.url,
      });
      notification.onclick = (event) => {
        console.log("onMessage click", event);
        if (event?.target?.data) {
          window.open(event.target.data, "_blank");
        }
      };
    } catch (e) {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .register("./firebase-messaging-sw.js", { scope: "./" })
          .then((registration) => {
            console.log("Service Worker Registered");
            setTimeout(() => {
              registration.showNotification(payload.notification.title, {
                body: payload.notification.body,
                data: payload.data.url,
              });
              registration.update();
            }, 100);
          })
          .catch((err) => {
            console.log("Service Worker Failed to Register", err);
          });
      }
    }
  }
});
export const FirebaseContext = createContext({
  toggleStatus: () => null,
});
export const FirebaseProvider = ({ children }) => {
  const log = (event, params_ = {}) => {
    const params = {
      time: new Date().getTime(),
      eventName: event,
      path: window.location.pathname,
      ...params_,
    };

    console.log(event, params);
    logEvent(analytics, event, params);
  };
  const permission = async () => {
    log("permission_requested");
    try {
      const result = await Notification.requestPermission();
      const requested = window.localStorage.getItem("requested");
      if (result === "denied" && !requested) {
        log("permission_denied");
      } else if (result === "granted") {
        !requested && log("permission_granted");

        try {
          const currentToken = await getToken(messaging, {
            vapidKey:
              "BM4Ljk67hLAj-aTJvCbRvOEitqVZHDOlYfnHp0ahujKwbVdoPnkXzmWALlIGAsxTjuhuikyHEfvRl7Ifvh-IhjU",
          });
          if (currentToken) {
            log("init_user_token_success", { currentToken });
          } else {
            log("init_user_token_fail");
          }
        } catch (error) {
          log("init_user_token_error", { error });
        }
      }
      window.localStorage.setItem("requested", true);
    } catch (error) {
      log("permission_error", { error });
    }
  };
  return (
    <FirebaseContext.Provider value={{ log, permission }}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebaseContext = () => useContext(FirebaseContext);
